@font-face {
  font-family: "Crimson Text";
  src: url("../../public/fonts/CrimsonText-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Crimson Text";
  src: url("../../public/fonts/CrimsonText-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Crimson Text";
  src: url("../../public/fonts/CrimsonText-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Crimson Text";
  src: url("../../public/fonts/CrimsonText-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Crimson Text";
  src: url("../../public/fonts/CrimsonText-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Crimson Text";
  src: url("../../public/fonts/CrimsonText-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}
